
import {
  defineComponent, PropType, computed, toRefs, ref,
} from 'vue';

import { UUID } from '@/domains/common';
import { AtomType, getAtomCover } from '@/domains/atom';

import AtomPreviewCoverPlaceholder from '@/components/ui/AtomCoverPlaceholder/AtomCoverPlaceholder.vue';

interface IOptions {
  size: `${string}x${string}`;
}

export default defineComponent({
  name: 'AtomCover',
  components: {
    AtomPreviewCoverPlaceholder,
  },
  props: {
    catalogueAtomVersionId: {
      type: String as PropType<UUID>,
      required: true,
    },
    atomType: {
      type: String as PropType<AtomType>,
      required: true,
    },
    options: {
      type: Object as PropType<IOptions>,
      default: () => ({}),
    },
    isOverlay: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { catalogueAtomVersionId, options } = toRefs(props);
    const atomCoverUrl = computed<string>(() => getAtomCover(catalogueAtomVersionId.value, options.value));

    const isImageLoadingError = ref(false);

    const onCoverLoadErrorHandler = () => {
      isImageLoadingError.value = true;
    };

    return {
      atomCoverUrl,
      isImageLoadingError,
      onCoverLoadErrorHandler,
    };
  },
});
